@tailwind base;
@tailwind components;
@tailwind utilities;

.hero {
  background-image: url("https://images.zeiss.com/Vision/Vision/International/images/section-bg/products/zeiss-digital-lenses-kv.ts-1474031486208.jpg?auto=compress%2Cformat&fm=png&ixlib=java-1.1.11&w=1920&s=2bca749a5d0aa47e69481b69d17e4285");
  background-size: cover;
}

@layer components {
  .wrapper {
    @apply py-2 px-4 bg-blue-500 text-white font-semibold rounded-lg shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75;
  }
}
